/*------------------------------------*\
  rapworldmap.com - main.css
  @author Crucifix Arnaud (hello@crucifixarnaud.com)
\*------------------------------------*/
/* ==========================================================================
   Normalize.scss settings
   ========================================================================== */
/**
 * Includes legacy browser support IE6/7
 *
 * Set to false if you want to drop support for IE6 and IE7
 */
/* Base
   ========================================================================== */
/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS and IE text size adjust after device orientation change,
 *    without disabling user zoom.
 * 3. Corrects text resizing oddly in IE 6/7 when body `font-size` is set using
 *  `em` units.
 */
html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Remove default margin.
 */
body {
  margin: 0; }

/* HTML5 display definitions
   ========================================================================== */
/**
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11
 * and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

/**
 * 1. Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */
audio,
canvas,
progress,
video {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
 * Prevents modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/10/11, Safari, and Firefox < 22.
 */
[hidden],
template {
  display: none; }

/* Links
   ========================================================================== */
/**
 * Remove the gray background color from active links in IE 10.
 */
a {
  background-color: transparent; }

/**
 * Improve readability of focused elements when they are also in an
 * active/hover state.
 */
a:active, a:hover {
  outline: 0; }

/* Text-level semantics
   ========================================================================== */
/**
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */
abbr[title] {
  border-bottom: 1px dotted; }

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */
b,
strong {
  font-weight: bold; }

/**
 * Address styling not present in Safari and Chrome.
 */
dfn {
  font-style: italic; }

/**
 * Address variable `h1` font-size and margin within `section` and `article`
 * contexts in Firefox 4+, Safari, and Chrome.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/**
 * Addresses styling not present in IE 8/9.
 */
mark {
  background: #ff0;
  color: #000; }

/**
 * Address inconsistent and variable font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* Embedded content
   ========================================================================== */
/**
 * 1. Remove border when inside `a` element in IE 8/9/10.
 * 2. Improves image quality when scaled in IE 7.
 */
img {
  border: 0; }

/**
 * Correct overflow not hidden in IE 9/10/11.
 */
svg:not(:root) {
  overflow: hidden; }

/* Grouping content
   ========================================================================== */
/**
 * Address margin not present in IE 8/9 and Safari.
 */
figure {
  margin: 1em 40px; }

/**
 * Address differences between Firefox and other browsers.
 */
hr {
  box-sizing: content-box;
  height: 0; }

/**
 * Contain overflow in all browsers.
 */
pre {
  overflow: auto; }

/**
 * Address odd `em`-unit font size rendering in all browsers.
 * Correct font family set oddly in IE 6, Safari 4/5, and Chrome.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

/* Forms
   ========================================================================== */
/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */
/**
 * 1. Correct color not being inherited.
 *  Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 * 4. Improves appearance and consistency in all browsers.
 */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
  margin: 0;
  /* 3 */ }

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */
button {
  overflow: visible; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *  and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *  `input` and others.
 * 4. Removes inner spacing in IE 7 without affecting normal text inputs.
 *  Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * Remove inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
input {
  line-height: normal; }

/**
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 *  Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome.
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  box-sizing: content-box;
  /* 2 */ }

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 * 3. Corrects text not wrapping in Firefox 3.
 * 4. Corrects alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */
textarea {
  overflow: auto; }

/**
 * Don't inherit the `font-weight` (applied by a rule above).
 * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
 */
optgroup {
  font-weight: bold; }

/* Tables
   ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

/*------------------------------------*\
  > Functions
  Usefull functions use every where in the applications
\*------------------------------------*/
/**
 * [parseInt Return an integer from a value with a unit]
 * @param  {number} $n [A number]
 * @return {int}    [The related integer of the given ($n) number]
 */
/**
 * [color-diff Return a map of the difference between two color]
 * @param  {color} $color-a [Any valid css color]
 * @param  {color} $color-b [Any valid css color]
 * @return {map}          [A map of the diff hue, saturation, ligthness between the two color]
 */
/**
 * [z Look for the given argument in a layers map to see if it is mapped to a z-index value]
 * @param  {string} $layer [Layer to search for]
 * @return {int} [Z-index value]
 */
/*------------------------------------*\
  > Variables
\*------------------------------------*/
/*------------------------------------*\
  > Mixins
  Generic and common mixins
\*------------------------------------*/
/**
 * [rem Return good rem value related to a given function, and add a fallback with pixel]
 * @param  {string} $property [Any property accepting rem/px value]
 * @param  {number} $values   [A number with the wanted final value in px]
 */
/**
 * [Vertical Center]
 * @param  {string} $position [Position type of the element] (default: relative)
 */
/**
 * [Horizontal Center]
 * Horizontaly align item
 * @param  {string} $position [Position type of the element] (default: relative)
 */
/**
 * [Center Align]
 * @param  {string} $position [Position type of the element] (default: relative)
 */
/**
 * [triangle]
 * Display a triangle (use for carret)
 * @param  {width (+ unit)} $size [Size of the triangle]
 * @param  {string} $color [Color of the triangle]
 * @param  {string} $direction [Direction of the triangle, top, right, down, left]
 */
/**
 * [debug-map Output content of a sass map for debug purpose]
 * @param  {map} $map [The map to debug]
 */
/*------------------------------------*\
  > Fonts
\*------------------------------------*/
@font-face {
  font-family: 'liberation';
  src: url("../fonts/LiberationSans/LiberationSans-Regular-webfont.eot");
  src: url("../fonts/LiberationSans/LiberationSans-Regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/LiberationSans/LiberationSans-Regular-webfont.woff") format("woff"), url("../fonts/LiberationSans/LiberationSans-Regular-webfont.ttf") format("truetype"), url("../fonts/LiberationSans/LiberationSans-Regular-webfont.svg#liberation_sansregular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'liberation';
  src: url("../fonts/LiberationSans/LiberationSans-Bold-webfont.eot");
  src: url("../fonts/LiberationSans/LiberationSans-Bold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/LiberationSans/LiberationSans-Bold-webfont.woff") format("woff"), url("../fonts/LiberationSans/LiberationSans-Bold-webfont.ttf") format("truetype"), url("../fonts/LiberationSans/LiberationSans-Bold-webfont.svg#liberation_sansregular") format("svg");
  font-weight: bold;
  font-style: normal; }

/*------------------------------------*\
  > Typograpphy
  Define all typography global rules heres
  (fontface are handle in fonts.scss file)
\*------------------------------------*/
html {
  font-size: 62.5%; }

body {
  font-family: liberation, sans-serif;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400; }

a {
  color: #ffd700;
  text-decoration: none; }
  a:hover, a:focus, a:active {
    text-decoration: underline;
    outline-color: #ffd700; }

abbr[title] {
  border-bottom: 0; }

/*------------------------------------*\
  > Layout
  All default layout styles
\*------------------------------------*/
* {
  box-sizing: border-box; }

body {
  background: #000;
  height: 100%;
  width: 100%; }

.main-title {
  margin: 0; }

/*------------------------------------*\
  > Button
  All style & mixins for generic buttons
\*------------------------------------*/
.button,
[class*="button--"] {
  display: inline-block;
  border-radius: 2px;
  border: 0;
  text-decoration: none;
  text-align: center;
  cursor: pointer; }
  .button:hover, .button:focus, .button:active,
  [class*="button--"]:hover,
  [class*="button--"]:focus,
  [class*="button--"]:active {
    text-decoration: none; }


.button.disable,
[class*="button--"].disable {
  cursor: not-allowed;
  pointer-events: none; }

.button, .button--primary, .button--danger {
  font-size: 15px;
  font-size: 1.5rem;
  padding: 0 1.5rem;
  line-height: 1.8; }

.button--primary--md {
  font-size: 15px;
  font-size: 1.5rem;
  padding: 0 3rem;
  line-height: 2.8; }


[class*='button--primary'] {
  position: relative;
  background-color: #ffd700;
  background-image: linear-gradient(to right, #ffd700, #ffeaa3 100%);
  background-size: 400% 400%;
  color: #1b2b34;
  transition: background-position 0.5s ease-in-out, background-color 0.2s ease-in-out; }
  
  [class*='button--primary']:after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: .5;
    background: -250px center no-repeat;
    border-radius: 4px;
    background-clip: padding-box;
    transition: all .5s ease-in-out;
    background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0) 25%, #fff4c2 25%, #fff4c2 50%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0) 60%, #fff4c2 60%, #fff4c2 70%, rgba(255, 255, 255, 0) 70%, rgba(255, 255, 255, 0) 76%, #fff4c2 76%, #fff4c2 80%, rgba(255, 255, 255, 0) 80%); }
  
  [class*='button--primary']:hover:not(.disable),
  [class*='button--primary']:focus:not(.disable) {
    outline: none;
    background-position: 98% 50%;
    transition: background-position 1s ease-in-out, background-color 0.2s ease-in-out, color .2s ease-in-out;
    background-color: #ffeaa3;
    color: #000; }
    
    [class*='button--primary']:hover:not(.disable):after,
    [class*='button--primary']:focus:not(.disable):after {
      background-position: 200px center; }
  
  [class*='button--primary']:active:not(.disable) {
    background-image: none;
    background-color: #fad300; }


[class*='button--danger'] {
  position: relative;
  background-color: #db3737;
  color: #fff;
  transition: background-color 0.2s ease-in-out; }
  
  [class*='button--danger']:hover:not(.disable),
  [class*='button--danger']:focus:not(.disable) {
    outline: none;
    background-color: #bd2222; }
  
  [class*='button--danger']:active:not(.disable) {
    background-image: none;
    background-color: #921a1a; }

.button--link {
  border: 0;
  background-color: transparent;
  color: #ffd700;
  border-radius: 0;
  padding: 0; }
  .button--link:hover, .button--link:focus, .button--link:active {
    text-decoration: underline; }
  .button--link:hover {
    outline: none; }
  .button--link:focus {
    outline-color: #ffd700; }

.button--link--large {
  border: 0;
  background-color: transparent;
  color: #ffd700;
  border-radius: 0;
  padding: 0;
  font-size: 1.6rem; }
  .button--link--large:hover, .button--link--large:focus, .button--link--large:active {
    text-decoration: underline; }
  .button--link--large:hover {
    outline: none; }
  .button--link--large:focus {
    outline-color: #ffd700; }

/*------------------------------------*\
  > Modal
\*------------------------------------*/
.modal {
  visibility: hidden;
  position: fixed;
  transition: visibility .2s, opacity .2s ease, transform .2s ease;
  opacity: 0;
  right: 0;
  background: rgba(27, 43, 52, 0.9);
  overflow: auto;
  width: 100%;
  height: 100%;
  pointer-events: auto;
  left: 0;
  top: 0;
  right: 0;
  padding: 1em 0 3em 4em;
  z-index: 1600;
  will-change: transform, opacity; }
  @media screen and (max-width: 767px) {
    .modal {
      min-width: 100%;
      padding: 1em; } }
  .modal.open {
    visibility: visible;
    opacity: 1; }

.modal__title {
  font-size: 22px;
  font-size: 2.2rem;
  font-weight: bold;
  outline-color: #ffd700; }

.modal__subtitle {
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: bold; }

.modal__link-close {
  border: 0;
  background-color: transparent;
  color: #ffd700;
  border-radius: 0;
  padding: 0;
  font-size: 12px;
  font-size: 1.2rem;
  cursor: pointer;
  margin-top: 2em; }
  .modal__link-close:hover, .modal__link-close:focus, .modal__link-close:active {
    text-decoration: underline; }
  .modal__link-close:hover {
    outline: none; }
  .modal__link-close:focus {
    outline-color: #ffd700; }

.modal__button-close {
  top: 15px; }
  @media screen and (max-width: 767px) {
    .modal__button-close {
      right: 15px; } }
  @media screen and (min-width: 768px) {
    .modal__button-close {
      right: 35px; } }

.modal__body {
  font-size: 16px;
  font-size: 1.6rem;
  max-width: 800px; }
  @media screen and (max-width: 479px) {
    .modal__body {
      margin-top: 4em; } }
  @media screen and (min-width: 768px) {
    .modal__body {
      margin-top: 5em; } }

.modal__body p {
  margin-left: .5em;
  line-height: 1.5; }

/*------------------------------------*\
  > Navigation
  Resuable navigation components
\*------------------------------------*/
.menu,
.submenu {
  list-style-type: none;
  padding: 0; }

.menu__item__button,
.submenu__item__button {
  pointer-events: auto;
  font-size: 14px;
  font-size: 1.4rem;
  border: 0;
  background-color: transparent;
  color: #fff;
  transition: color 0.2s ease-in-out; }
  .menu__item__button:hover:not(.disable), .menu__item__button:focus:not(.disable),
  .submenu__item__button:hover:not(.disable),
  .submenu__item__button:focus:not(.disable) {
    color: #e6e6e6; }
  .menu__item__button:hover:not(.disable),
  .submenu__item__button:hover:not(.disable) {
    outline: none; }
  .menu__item__button:focus:not(.disable),
  .submenu__item__button:focus:not(.disable) {
    outline-color: #fff; }
  .menu__item__button:active:not(.disable),
  .submenu__item__button:active:not(.disable) {
    color: #cccccc; }
  .menu__item__button:hover:not(.disable), .menu__item__button:focus:not(.disable), .menu__item__button:active:not(.disable),
  .submenu__item__button:hover:not(.disable),
  .submenu__item__button:focus:not(.disable),
  .submenu__item__button:active:not(.disable) {
    outline: none; }

.menu__item__button:hover .button__label,
.menu__item__button:focus .button__label,
.menu__item__button:active .button__label {
  text-decoration: underline; }

/*------------------------------------*\
  > Mapbox specific
\*------------------------------------*/
.mapbox-logo {
  display: none; }

.leaflet-cluster-anim .leaflet-marker-icon, .leaflet-cluster-anim .leaflet-marker-shadow {
  transition: transform 0.3s ease-out, opacity 0.3s ease-in; }

.leaflet-cluster-spider-leg {
  /* stroke-dashoffset (duration and function) should match with leaflet-marker-icon transform in order to track it exactly */
  transition: stroke-dashoffset 0.3s ease-out, stroke-opacity 0.3s ease-in; }

.marker-cluster {
  background-clip: padding-box;
  border-radius: 20px; }

.marker-cluster div {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;
  text-align: center;
  border-radius: 15px; }

.marker-cluster span {
  line-height: 2.6; }

/*------------------------------------*\
  > Form
\*------------------------------------*/
[type="text"],
[type="email"],
[type="password"],
[type="date"],
[type="number"],
textarea {
  display: block;
  padding: 5px;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  width: 100%;
  font-size: 14px;
  font-size: 1.4rem;
  background: #111a20; }
  [type="text"]:focus,
  [type="email"]:focus,
  [type="password"]:focus,
  [type="date"]:focus,
  [type="number"]:focus,
  textarea:focus {
    outline: none;
    box-shadow: 0 2px 0 #ffd700; }

textarea {
  min-height: 250px; }

[type="checkbox"]:not(:checked),
[type="checkbox"]:checked,
[type="radio"]:not(:checked),
[type="radio"]:checked {
  opacity: 0;
  position: absolute; }

[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label,
[type="radio"]:not(:checked) + label,
[type="radio"]:checked + label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  cursor: pointer; }

[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before,
[type="radio"]:not(:checked) + label:before,
[type="radio"]:checked + label:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  background: #111a20;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  cursor: pointer; }

/* Fake Checked mark aspect */
[type="checkbox"]:not(:checked) + label:hover:after,
[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after,
[type="radio"]:not(:checked) + label:hover:after,
[type="radio"]:not(:checked) + label:after,
[type="radio"]:checked + label:after {
  content: '';
  display: inline-block;
  position: absolute;
  width: 10px;
  height: 10px;
  top: 6px;
  left: 5px;
  color: #ffd700;
  transition: all .2s; }

[type="checkbox"]:not(:checked) + label:after,
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0); }

/* Hover style */
[type="checkbox"]:not(:checked) + label:hover:after,
[type="checkbox"]:focus:not(:checked) + label:after,
[type="radio"]:not(:checked) + label:hover:after,
[type="radio"]:focus:not(:checked) + label:after {
  opacity: 1;
  transform: scale(1);
  background: #2c4756; }

[type="checkbox"]:checked + label:after,
[type="radio"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
  background: #ffd700; }

/* disabled checkbox */
[type="checkbox"]:disabled:not(:checked) + label:before,
[type="checkbox"]:disabled:checked + label:before,
[type="radio"]:disabled:not(:checked) + label:before,
[type="radio"]:disabled:checked + label:before {
  background-color: #16232a; }

[type="checkbox"]:disabled + label,
[type="radio"]:disabled + label {
  color: gray; }

[type="checkbox"]:disabled + label,
[type="checkbox"]:disabled + label:before,
[type="radio"]:disabled + label,
[type="radio"]:disabled + label:before {
  cursor: not-allowed; }

/* accessibility */
[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
  border-radius: 3px; }

[type="checkbox"]:not(:checked) + label:hover:after,
[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after {
  border-radius: 2px; }

[type="radio"]:not(:checked) + label:before,
[type="radio"]:checked + label:before {
  border-radius: 10px; }

[type="radio"]:not(:checked) + label:hover:after,
[type="radio"]:not(:checked) + label:after,
[type="radio"]:checked + label:after {
  border-radius: 5px; }

.input--file {
  font-size: 16px;
  font-size: 1.6rem; }

.input--range {
  box-sizing: border-box;
  line-height: 1;
  background-color: transparent;
  cursor: pointer;
  -webkit-appearance: none;
  width: 100%; }
  .input--range::-webkit-slider-thumb {
    -webkit-appearance: none; }
  .input--range::-ms-track {
    width: 100%;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent; }
  .input--range:focus {
    outline: none; }
  .input--range::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 1.5em;
    height: 1.5em;
    margin-top: 0;
    background-color: #ffd700;
    border-radius: 1em;
    border: 1px solid #ffd700;
    cursor: pointer;
    transition: all .1s ease-in-out; }
  .input--range::-moz-range-thumb {
    width: 1.5em;
    height: 1.5em;
    margin-top: 0;
    background-color: #ffd700;
    border-radius: 1em;
    border: 1px solid #ffd700;
    cursor: pointer;
    transition: all .1s ease-in-out; }
  .input--range::-ms-thumb {
    width: 1.5em;
    height: 1.5em;
    margin-top: 0;
    background-color: #ffd700;
    border-radius: 1em;
    border: 1px solid #ffd700;
    cursor: pointer;
    transition: all .1s ease-in-out; }
  .input--range:focus::-webkit-slider-thumb, .input--range:hover::-webkit-slider-thumb {
    background-color: #ffe766;
    border-color: #ffe766; }
  .input--range:focus::-moz-range-thumb, .input--range:hover::-moz-range-thumb {
    background-color: #ffe766;
    border-color: #ffe766; }
  .input--range:focus::-ms-thumb, .input--range:hover::-ms-thumb {
    background-color: #ffe766;
    border-color: #ffe766; }
  .input--range:active::-webkit-slider-thumb {
    background-color: #2c4756;
    border-color: #2c4756; }
  .input--range:active::-moz-range-thumb {
    background-color: #2c4756;
    border-color: #2c4756; }
  .input--range:active::-ms-thumb {
    background-color: #2c4756;
    border-color: #2c4756; }
  .input--range::-webkit-slider-runnable-track {
    width: 100%;
    cursor: pointer;
    height: 1em;
    border-bottom: 4px solid #111a20;
    background-color: transparent; }
  .input--range::-moz-range-track {
    width: 100%;
    cursor: pointer;
    height: 1em;
    border-bottom: 4px solid #111a20;
    background-color: transparent; }
  .input--range::-ms-track {
    background: transparent;
    border-color: transparent;
    color: transparent; }

.field {
  position: relative;
  margin: 0 0 .5em; }

.field__label {
  display: block;
  margin-bottom: .4em;
  font-size: 16px;
  font-size: 1.6rem; }

.field__label--optional {
  font-size: 12px;
  font-size: 1.2rem;
  vertical-align: middle; }

.field--inline {
  display: inline-block;
  margin-right: 1em; }
  .field--inline:last-child {
    margin-right: 0; }

.field-group {
  margin: 1.5em 0;
  padding-left: .5em; }
  .field-group:first-of-type {
    margin-top: 0; }
  .field-group:last-of-type {
    margin-bottom: 0; }

.field-group__title {
  margin: 0 0 0.5em -0.5em;
  font-size: 18px;
  font-size: 1.8rem;
  color: #ffd700; }

.errors {
  font-size: 15px;
  font-size: 1.5rem;
  margin-bottom: 1em; }

.error {
  color: #e52323; }

.error p {
  margin: 0; }

.success {
  background: #3eb36f;
  padding: .5em 1em;
  margin-bottom: 2em;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 0;
  border-radius: 2px; }

.field-error {
  display: block;
  margin-top: .5em;
  color: #e52323;
  font-size: 12px;
  font-size: 1.2rem; }

/*------------------------------------*\
  > Atlas Notifications
\*------------------------------------*/
.notifications {
  z-index: 5000;
  top: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%); }

.notifications__list {
  list-style-type: none;
  margin: 0; }

.notification {
  position: relative;
  overflow: hidden;
  font-size: 14px;
  font-size: 1.4rem; }

.notification__content {
  white-space: pre;
  word-break: break-word;
  padding: 1em 1em;
  display: block;
  line-height: 1.2; }

.notification--success {
  background: #3eb36f; }

/*------------------------------------*\
  > Panel
  All style for panel (submit, artist, etc)
\*------------------------------------*/
.panel {
  position: fixed;
  height: 100%;
  width: 33vw;
  right: 0;
  transform: translateX(100%);
  transition: transform .2s ease;
  padding: 2em;
  overflow-y: auto; }

.panel.open {
  transform: translateX(0); }

.panel-button--close {
  line-height: 1;
  float: right;
  transition: color .2s ease;
  font-size: 30px;
  font-size: 3rem; }

.panel__title {
  font-size: 18px;
  font-size: 1.8rem; }

.artist-panel:focus {
  outline: none; }

@media screen and (max-width: 767px) {
  .artist-panel {
    visibility: hidden;
    position: fixed;
    transition: visibility .2s, opacity .2s ease, transform .2s ease;
    opacity: 0;
    right: 0;
    background: rgba(27, 43, 52, 0.9);
    overflow: auto;
    width: 100%;
    height: 100%;
    pointer-events: auto;
    left: 0;
    top: 0;
    right: 0;
    padding: 1em 0 3em 4em;
    z-index: 1600;
    will-change: transform, opacity;
    z-index: 1750; } }
  @media screen and (max-width: 767px) and (max-width: 767px) {
    .artist-panel {
      min-width: 100%;
      padding: 1em; } }

@media screen and (max-width: 767px) {
    .artist-panel.open {
      visibility: visible;
      opacity: 1; } }

@media screen and (min-width: 768px) {
  .artist-panel {
    visibility: hidden;
    position: absolute;
    transition: visibility .2s, transform .2s ease;
    bottom: 0;
    right: 0;
    width: 620px;
    min-height: 500px;
    padding: 1em;
    z-index: 1000;
    right: 0;
    transform: translateX(120%); } }

.artist-panel.open {
  visibility: visible;
  transform: translateX(0); }

.artist-panel__thumbnail {
  position: absolute; }
  @media screen and (max-width: 767px) {
    .artist-panel__thumbnail {
      top: 80px; } }
  @media screen and (min-width: 768px) {
    .artist-panel__thumbnail {
      top: -70px;
      left: -70px;
      opacity: 0;
      transform: rotate(1turn) translateX(100%);
      animation-duration: .5s;
      animation-timing-function: cubic-bezier(0.05, 1.1, 0.98, 1);
      animation-delay: .2s;
      animation-fill-mode: forwards;
      will-change: transform,opacity; } }

@keyframes display-circle {
  to {
    opacity: 1;
    transform: translateX(0); } }

.artist-panel.open .artist-panel__thumbnail {
  animation-name: display-circle; }

@media screen and (max-width: 767px) {
  .artist-panel__body {
    margin-top: 6em; } }

@media screen and (min-width: 768px) {
  .artist-panel__body {
    max-width: 604px;
    min-height: 300px;
    background: url("../images/artist-panel-background.svg") no-repeat;
    background-size: cover;
    background-position: 0 0;
    padding: 1em; } }

.artist-panel__name {
  margin: 20px 0 .2em 130px;
  color: #ffd700;
  font-size: 24px;
  font-size: 2.4rem; }

.artist-panel__categories {
  margin: 0 0 .2em 130px;
  padding: 0; }

.artist-panel__categories__item {
  font-size: 11px;
  font-size: 1.1rem;
  background: #1b2b34;
  display: inline-block;
  padding: 4px 10px 2px;
  margin: 0 .5em 0 0;
  text-transform: uppercase;
  color: #1b2b34;
  background: #ffd700;
  font-weight: bold;
  border-radius: 2px; }

.artist-panel__bio {
  font-size: 16px;
  font-size: 1.6rem; }
  @media screen and (min-width: 768px) {
    .artist-panel__bio {
      margin: 30px 0 3em 20px; } }

.artist-panel__bio p {
  margin-bottom: .5em; }

.artist-panel__readmore {
  font-size: 12px;
  font-size: 1.2rem; }
  .artist-panel__readmore:after {
    content: '\21AA';
    display: inline-block;
    vertical-align: bottom;
    line-height: 10px;
    margin-left: 3px; }

.button--close {
  position: absolute;
  border: 0;
  background-color: transparent;
  color: #ffd700;
  transition: color 0.2s ease-in-out;
  font-size: 30px;
  font-size: 3rem; }
  .button--close:hover:not(.disable), .button--close:focus:not(.disable) {
    color: #ccac00; }
  .button--close:hover:not(.disable) {
    outline: none; }
  .button--close:focus:not(.disable) {
    outline-color: #ffd700; }
  .button--close:active:not(.disable) {
    color: #998100; }

@media screen and (max-width: 767px) {
  .artist-panel__button--close {
    top: 1em;
    right: 15px; } }

@media screen and (min-width: 768px) {
  .artist-panel__button--close {
    top: 0.5em;
    right: 1em; } }

.artist-panel__thumbnail--preview {
  margin-bottom: 1em; }

@keyframes display-youtubeClip {
  to {
    opacity: 1; } }

.artist-panel__youtube {
  position: relative; }
  @media screen and (max-width: 767px) {
    .artist-panel__youtube {
      margin: 1em 0;
      width: 100%; } }
  @media screen and (min-width: 768px) {
    .artist-panel__youtube {
      position: relative;
      min-height: 182px;
      min-width: 340px;
      opacity: 0;
      animation-name: display-youtubeClip;
      animation-duration: .5s;
      animation-timing-function: cubic-bezier(0.05, 1.1, 0.98, 1);
      animation-delay: .2s;
      animation-fill-mode: forwards;
      will-change: transform,opacity;
      margin-right: 2em;
      margin-top: -3em; } }

@media screen and (max-width: 767px) {
  .artist-panel__youtube__embed {
    background: #ffd700;
    padding: 1em;
    width: 100%; } }

@media screen and (min-width: 768px) {
  .artist-panel__youtube__embed {
    position: absolute;
    right: 15px;
    z-index: 1000; } }

@media screen and (max-width: 767px) {
  .artist-panel__youtube__background {
    display: none; } }

@media screen and (min-width: 768px) {
  .artist-panel__youtube__background {
    position: absolute;
    right: -5px;
    top: -15px; } }

.artist-panel__location,
.artist-panel__yearsactive {
  display: inline-block;
  font-size: 14px;
  font-size: 1.4rem; }

.artist-panel__location {
  margin: 0 0 10px 130px; }

.artist-panel__location__city {
  cursor: pointer; }

.artist-panel__yearsactive {
  margin: 0 0 10px 20px; }
  @media screen and (max-width: 767px) {
    .artist-panel__yearsactive {
      font-size: 10px;
      font-size: 1rem; } }
  @media screen and (max-width: 767px) {
    .artist-panel__yearsactive {
      display: block;
      margin-left: 130px; } }

.menu__item--submit-artist {
  position: relative; }
  @media screen and (min-width: 768px) {
    .menu__item--submit-artist {
      margin-bottom: .5em; } }

.submit-artist-panel:focus {
  outline: none; }

@media screen and (max-width: 767px) {
  .submit-artist-panel {
    visibility: hidden;
    position: fixed;
    transition: visibility .2s, opacity .2s ease, transform .2s ease;
    opacity: 0;
    right: 0;
    background: rgba(27, 43, 52, 0.9);
    overflow: auto;
    width: 100%;
    height: 100%;
    pointer-events: auto;
    left: 0;
    top: 0;
    right: 0;
    padding: 1em 0 3em 4em;
    z-index: 1600;
    will-change: transform, opacity; } }
  @media screen and (max-width: 767px) and (max-width: 767px) {
    .submit-artist-panel {
      min-width: 100%;
      padding: 1em; } }

@media screen and (max-width: 767px) {
    .submit-artist-panel.open {
      visibility: visible;
      opacity: 1; } }

@media screen and (min-width: 768px) {
  .submit-artist-panel {
    visibility: hidden;
    position: absolute;
    z-index: -1;
    transition: visibility .2s, transform .2s ease;
    right: 0;
    max-width: 600px;
    pointer-events: auto;
    left: 0;
    top: 0;
    padding: 1em 4em;
    z-index: 1000;
    right: 0;
    transform: translateX(-120%);
    will-change: transform,opacity;
    background: url("../images/submit-artist-panel-background.svg") no-repeat;
    background-size: cover; } }

@media screen and (min-width: 768px) {
  .submit-artist-panel.open {
    visibility: visible;
    transform: translateX(125px); } }

.submit-artist-panel__content {
  max-width: 500px;
  padding: 0 1em; }
  @media screen and (max-width: 767px) {
    .submit-artist-panel__content {
      margin-top: 6em; } }

.submit-artist-panel__button--close {
  top: 15px; }
  @media screen and (max-width: 767px) {
    .submit-artist-panel__button--close {
      right: 15px; } }
  @media screen and (min-width: 768px) {
    .submit-artist-panel__button--close {
      right: 35px; } }

.modal--search .modal__body {
  max-width: none; }

.menu__item--search-artist {
  position: relative; }
  @media screen and (min-width: 768px) {
    .menu__item--search-artist {
      margin-bottom: .5em; } }

@media screen and (max-width: 767px) {
  .search-artist-panel {
    visibility: hidden;
    position: fixed;
    transition: visibility .2s, opacity .2s ease, transform .2s ease;
    opacity: 0;
    right: 0;
    background: rgba(27, 43, 52, 0.9);
    overflow: auto;
    width: 100%;
    height: 100%;
    pointer-events: auto;
    left: 0;
    top: 0;
    right: 0;
    padding: 1em 0 3em 4em;
    z-index: 1600;
    will-change: transform, opacity; } }
  @media screen and (max-width: 767px) and (max-width: 767px) {
    .search-artist-panel {
      min-width: 100%;
      padding: 1em; } }

@media screen and (max-width: 767px) {
    .search-artist-panel.open {
      visibility: visible;
      opacity: 1; } }

@media screen and (min-width: 768px) {
  .search-artist-panel {
    visibility: hidden;
    position: absolute;
    z-index: -1;
    transition: visibility .2s, transform .2s ease;
    right: 0;
    max-width: 600px;
    pointer-events: auto;
    left: 0;
    top: 0;
    padding: 1em 4em;
    z-index: 1000;
    right: 0;
    transform: translateX(-120%);
    will-change: transform,opacity;
    background: url("../images/search-artist-panel-background.svg") no-repeat;
    background-size: cover; } }

@media screen and (min-width: 768px) {
  .search-artist-panel.open {
    visibility: visible;
    transform: translateX(125px); } }

.search-artist-panel__content {
  max-width: 500px;
  padding: 0 1em; }
  @media screen and (max-width: 767px) {
    .search-artist-panel__content {
      margin-top: 6em; } }

.search-artist-panel__button--close {
  top: 15px; }
  @media screen and (max-width: 767px) {
    .search-artist-panel__button--close {
      right: 15px; } }
  @media screen and (min-width: 768px) {
    .search-artist-panel__button--close {
      right: 35px; } }

.field--search {
  max-width: 800px; }

.field--search__input,
.field--search__button {
  display: inline-block; }

.field--search__input {
  max-width: 50%; }
  @media screen and (min-width: 768px) {
    .field--search__input {
      max-width: 75%; } }

.field--search__button {
  margin-left: 1em; }

.search-panel__body {
  font-size: 16px;
  font-size: 1.6rem; }
  @media screen and (max-width: 479px) {
    .search-panel__body {
      margin-top: 4em; } }
  @media screen and (min-width: 768px) {
    .search-panel__body {
      margin-top: 5em; } }

.search-result {
  list-style-type: none;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  padding: 0; }

.search-result__item {
  text-align: center;
  margin: .5em;
  transition: background .2s ease; }
  @media screen and (max-width: 479px) {
    .search-result__item {
      width: calc(50% - 20px); } }
  @media screen and (min-width: 768px) {
    .search-result__item {
      width: calc(10% - 20px); } }

.search-result__item__link {
  display: block;
  font-size: 14px;
  font-size: 1.4rem;
  cursor: pointer;
  padding: 0;
  background: transparent;
  border: 0;
  color: #ffd700;
  appearance: none;
  width: 100%; }
  .search-result__item__link:hover, .search-result__item__link:focus, .search-result__item__link:active {
    text-decoration: none; }

.search-result__item__thumbnail {
  margin: 0 auto;
  position: relative; }

.search-result__item__thumbnail:after {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background: none;
  position: absolute;
  top: 0;
  transition: background .2s ease; }

.search-result__item__link:hover, .search-result__item__link:focus, .search-result__item__link:active {
  outline-color: #ffd700; }

.search-result__item__link:hover .search-result__item__thumbnail:after,
.search-result__item__link:focus .search-result__item__thumbnail:after,
.search-result__item__link:active .search-result__item__thumbnail:after {
  background: rgba(255, 215, 0, 0.4); }

.search-result__item__thumbnail img {
  width: 100%; }

.search-result__item__location {
  font-size: 12px;
  font-size: 1.2rem; }

/*------------------------------------*\
  > Thumbnails
  All style for thumbnails
\*------------------------------------*/
.thumbnail {
  display: block;
  overflow: hidden;
  background: #1b2b34;
  border: 4px solid #ffd700; }
  @media screen and (max-width: 767px) {
    .thumbnail {
      height: 100px;
      width: 100px;
      border-radius: 50px; } }
  @media screen and (min-width: 768px) {
    .thumbnail {
      height: 200px;
      width: 200px;
      border-radius: 100px; } }

.thumbnail__picture {
  display: block;
  width: 100%; }

.thumbnail--small {
  display: block;
  overflow: hidden;
  background: #1b2b34;
  height: 100px;
  width: 100px;
  border-radius: 50px;
  border: 2px solid #ffd700; }

/*------------------------------------*\
  > Loader
\*------------------------------------*/
.no-js .loader {
  display: none; }

.loader {
  z-index: 1750;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background: #16232a;
  opacity: .1;
  visibility: hidden;
  transition: opacity .4s ease, visibility .4s ease; }

.loader.active {
  visibility: visible;
  opacity: 1; }

.loader__spin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.loader__spin:before,
.loader__spin:after {
  content: "";
  position: absolute;
  top: -50px;
  left: -50px;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  border: 5px solid transparent;
  border-top-color: #fff; }

.loader__spin:before {
  z-index: 1751;
  animation: spin 1.2s infinite linear; }

.loader__spin:after {
  border: 5px solid #121d23; }

.loader--atlas {
  background: #000; }

.loader--atlas .loader__spin:after {
  border: 5px solid #222; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/*------------------------------------*\
  > Dropdown
\*------------------------------------*/
.dropdown__button {
  color: #fff;
  cursor: pointer;
  position: relative;
  padding-right: 2rem;
  transition: color .2s ease;
  font-size: 14px;
  font-size: 1.4rem; }
  .dropdown__button:hover, .dropdown__button:focus, .dropdown__button:active {
    color: #ffd700;
    text-decoration: none; }
    .dropdown__button:hover:after, .dropdown__button:focus:after, .dropdown__button:active:after {
      border-top-color: #ffd700; }
  .dropdown__button:after {
    transition: color 0.2s ease, transform 0.3s cubic-bezier(1, 0, 0, 1), margin 0.3s cubic-bezier(1, 0, 0, 1);
    content: '';
    width: 0;
    height: 0;
    border: 0.4rem solid transparent;
    border-top: 0.4rem solid #fff;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    margin-top: 1px; }

.dropdown__button.open:after {
  transform-origin: 50%;
  transform: translateY(-50%) rotate(-180deg);
  margin-top: -2px; }

.dropdown__content {
  min-width: 20rem;
  position: absolute;
  margin-top: .5rem;
  margin-left: -1rem;
  padding: 1rem;
  font-size: 14px;
  font-size: 1.4rem;
  background: #16232a;
  border: 1px solid #000;
  z-index: 2000; }

.dropdown__list {
  list-style-type: none;
  padding: 0;
  margin: 0; }

.dropdown__item__item {
  padding: 0;
  margin: 0; }

.dropdown__item__link {
  color: #fff;
  display: block;
  width: 100%;
  padding: .6rem;
  position: relative;
  cursor: pointer; }
  .dropdown__item__link:hover, .dropdown__item__link:focus, .dropdown__item__link:active {
    background: #121d23;
    text-decoration: none; }

.dropdown__item__link.active {
  color: #ffd700; }
  .dropdown__item__link.active:after {
    display: block;
    position: absolute;
    content: "\2713";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: .5em; }

/*------------------------------------*\
  > Page Atlas
  Specific style for atlas page
\*------------------------------------*/
.page-atlas {
  padding: 0;
  overflow: hidden; }

.page-atlas .app-container {
  width: 100vw;
  height: 100vh; }

.atlas-header {
  display: block;
  width: 100%;
  min-height: 66px;
  position: relative;
  z-index: 2000;
  pointer-events: none; }

.atlas-header__title {
  margin: 0; }

.atlas-header__title a:hover, .atlas-header__title a:focus, .atlas-header__title a:active {
  text-decoration: none; }

@media screen and (max-width: 767px) {
  .atlas-header .main-logo {
    width: 80%;
    max-width: 350px;
    max-height: 66px; } }

.atlas-menu {
  pointer-events: none; }

@media screen and (max-width: 767px) {
  .atlas-menu .menu {
    margin: 0; } }

@media screen and (min-width: 768px) {
  .atlas-menu .menu {
    margin-left: 1em; } }

.atlas-menu .menu__item {
  position: relative; }

@media screen and (max-width: 767px) {
  .atlas-menu .menu__item__button {
    width: 90px;
    transform: scale(0.8); } }

@media screen and (min-width: 768px) {
  .atlas-menu .menu__item__button {
    width: 130px; } }

.atlas-menu .submenu__item__button:hover, .atlas-menu .submenu__item__button:focus, .atlas-menu .submenu__item__button:active {
  text-decoration: underline; }

.atlas-menu .button__icon {
  display: block;
  margin: 0 auto; }

.atlas-menu .button__label {
  display: inline-block;
  margin-top: .5em; }

.atlas-menu .submenu,
.atlas-menu .submenu__item,
.menu__item__button {
  display: inline-block;
  vertical-align: middle; }

.submenu--placeofinterests {
  position: absolute; }

.submenu--placeofinterests .submenu__item .button__icon,
.submenu--placeofinterests .submenu__item {
  transition: opacity 0.2s ease, transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275); }

.submenu--placeofinterests .submenu__item {
  margin: 0 -0.5em 2em 0;
  opacity: 0;
  transform: scale(0); }

.submenu--placeofinterests .submenu__item.open {
  opacity: 1;
  transform: scale(1); }

.submenu--placeofinterests .submenu__item__button:focus .button__icon,
.submenu--placeofinterests .submenu__item.open:hover .button__icon,
.submenu--placeofinterests .submenu__item.open:focus .button__icon,
.submenu--placeofinterests .submenu__item.open:active .button__icon {
  transform: scale(1.1); }

.submenu--placeofinterests .button__label {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  margin: .5em 0 0;
  width: 200%;
  transition: opacity .2s ease; }

.submenu--placeofinterests .submenu__item__button:focus .button__label,
.submenu--placeofinterests .submenu__item.open:hover .button__label,
.submenu--placeofinterests .submenu__item.open:focus .button__label,
.submenu--placeofinterests .submenu__item.open:active .button__label {
  opacity: 1; }

.mapbox {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  right: 0; }

.marker-wrapper {
  outline: none; }

.marker {
  white-space: nowrap; }

.leaflet-interactive.mouse-over {
  z-index: 750 !important; }

.marker__icon {
  display: block;
  border: none;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  cursor: pointer;
  background-size: cover;
  outline: none;
  transition: box-shadow 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  z-index: 500; }

.marker-wrapper:focus .marker__icon,
.marker:hover .marker__icon,
.marker:focus .marker__icon {
  box-shadow: 0 0 0 2px #ffd700; }

.marker__title {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  left: 75%;
  transform: translate(-25px, -50%);
  z-index: -1;
  padding-left: 25px;
  margin-top: -8px;
  font-weight: bold;
  color: #ffd700;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0.3s, opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1), transform 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  background: rgba(0, 0, 0, 0.5); }
  .marker__title:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 50%;
    height: 2px;
    background-color: #ffd700;
    transition: width 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) 0.2s; }

.marker-wrapper:focus .marker .marker__title,
.marker:hover .marker__title,
.marker:focus .marker__title {
  visibility: visible;
  opacity: 1;
  transform: translate(0, -50%); }
  .marker-wrapper:focus .marker .marker__title:after,
  .marker:hover .marker__title:after,
  .marker:focus .marker__title:after {
    width: 100%; }

.leaflet-container {
  font-family: liberation, sans-serif; }

.marker-cluster-small,
.marker-cluster-medium,
.marker-cluster-large {
  transition: background-color 0.3s cubic-bezier(0.39, 0.575, 0.565, 1); }

.marker-cluster-small {
  background-color: rgba(255, 0, 102, 0.2); }
  .marker-cluster-small:hover, .marker-cluster-small:focus, .marker-cluster-small:active {
    background-color: rgba(255, 0, 102, 0.5);
    outline-color: #ff0066; }

.marker-cluster-small div {
  background-color: rgba(255, 0, 102, 0.8); }

.marker-cluster-medium,
.marker-cluster-large {
  background-color: rgba(143, 57, 154, 0.2); }
  .marker-cluster-medium:hover, .marker-cluster-medium:focus, .marker-cluster-medium:active,
  .marker-cluster-large:hover,
  .marker-cluster-large:focus,
  .marker-cluster-large:active {
    background-color: rgba(143, 57, 154, 0.6);
    outline-color: #8f399a; }

.marker-cluster-medium div,
.marker-cluster-large div {
  background-color: rgba(143, 57, 154, 0.8); }

.atlas-footer {
  position: absolute;
  bottom: 0;
  left: 1em; }

.atlas-footer .menu__item {
  position: relative;
  z-index: 1500; }

@media screen and (max-width: 767px) {
  .atlas-footer .menu__item__button {
    font-size: 10px;
    font-size: 1rem; } }

/*------------------------------------*\
  > Page Errors
  Specific style for errors page
\*------------------------------------*/
.errors-container {
  padding: 2em;
  font-size: 18px;
  font-size: 1.8rem; }

.error__content {
  padding-left: 1em; }
